import React, { PropsWithChildren } from 'react';
import { Box, IconButton, Modal, ModalProps, Stack, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export type ModalChildProps = {
  onClose: () => void;
};

type Props = {
  title?: string;
  width?: string;
  color?: 'success' | 'error' | 'primary' | 'warning';
} & PropsWithChildren<ModalChildProps> &
  ModalProps;

export const AppModal: React.FC<Props> = ({ title, width = '', color, children, ...modalProps }) => {
  const backgroundColor = color ? `${color}.main` : 'primary.main';
  return (
    <Modal {...modalProps}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          minWidth: '480px',
          maxWidth: '980px',
          maxHeight: '94vh',
          bgcolor: 'background.paper',
          overflowY: 'scroll',
          boxShadow: 24,
          width,
        }}
      >
        {title && (
          <Stack
            direction="row"
            alignItems="center"
            sx={{
              backgroundColor,
              color: 'primary.contrastText',
              pt: 1,
              pb: 1,
              pl: 3,
              pr: 2,
            }}
          >
            <Typography variant="h5" sx={{ flexGrow: 1 }}>
              {title}
            </Typography>
            <IconButton onClick={modalProps.onClose} sx={{ color: 'primary.contrastText' }}>
              <CloseIcon />
            </IconButton>
          </Stack>
        )}
        <Box sx={{ p: 3 }}>{children}</Box>
      </Box>
    </Modal>
  );
};
